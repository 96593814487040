@media (max-width: 1399px) {
  .service-boy {
    right: 30px !important;

    &>span {
      width: 375px !important;
    }
  }

  .sale-service-content {
    width: 34% !important;
  }

  .sale-content-scroll {
    height: 230px !important;
  }

  .contact-wrapper {
    padding: 60px 30px 0 !important;
  }
}

@media (max-width: 1199px) {
  .logo svg {
    width: 140px !important;
  }

  .nav-bar-list {
    gap: 27px !important;
  }

  .about-content {
    padding: 50px 40px !important;
  }

  .sale-service-content {
    .lightTextGradient {
      font-size: 46px !important;
    }
  }

  .sale-content-scroll {
    height: 250px !important;
  }

  .service-boy {
    &>span {
      width: 330px !important;
    }
  }

  .tab-box-content {
    gap: 10px !important;
  }

  .footer-content>svg {
    width: 150px !important;
  }

  .footer-logo svg {
    width: 150px !important;
  }

  .footer-content>.gap-3 {
    gap: 0px !important;
  }
}

@media (max-width: 991px) {
  .social-link {
    display: none;
  }

  .nav-bar-list {
    display: none !important;
  }

  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--whiteColor);
  }

  .showToggle {
    position: absolute !important;
    top: 61px;
    left: 0;
    background: var(--darkBlue) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #dbdbdbd6;
  }

  .showToggle div {
    border-bottom: 1px solid var(--whiteColor);
    outline: none;
    width: 100%;
    padding: 20px 10px;
  }

  .showToggle a:hover {
    color: var(--darkTextGradient);
  }

  .showToggle .active {
    color: var(--darkTextGradient);
  }

  .showToggle .nav-bar-list a {
    color: var(--darkTextGradient);
    background: none !important;

    &:focus,
    &::before,
    &.active::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      background: transparent !important;
    }
  }

  .nav-bar-list a.active::before {
    background: transparent !important;
  }

  .sticky.py-2 {
    transition: all 0.4s ease-in-out;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  // nenu-responsive end

  .layers {
    height: auto !important;
    min-height: 550px !important;
    width: 100%;
  }

  .layer-mid-x-2 {
    position: relative;
    left: -210px;
  }

  .text-styled {
    position: relative;
    right: 9px !important;
    top: 15px !important;
    font-size: 200px !important;
  }

  .caption {
    font-size: 15px !important;
  }

  .about-content .w-50 {
    width: 85% !important;
  }

  .about-content {
    padding: 50px 40px !important;
    width: 100% !important;
  }

  .about-house {
    height: 230px !important;
    width: 250px !important;
    right: -220px !important;
  }

  .house-bird {
    // bottom: -140px !important;
    width: 230px !important;
  }

  .about-bg .w-25 {
    width: 50% !important;
  }

  .service-boy {
    display: none;
  }

  .heading-mini {
    font-size: 20px !important;
  }

  .heading-main {
    font-size: 46px !important;
  }

  // .about-bg .common-space-over{
  //   margin: 0 !important;
  // }
  .about-bg .common-space {
    padding: 50px 0 50px !important;
  }

  .sale-service-content {
    width: 100% !important;
  }

  .service-content-wrapper {
    padding: 40px 0 80px !important;
    flex-wrap: wrap !important;
    gap: 35px !important;
  }

  // .achived-content-wrapper {
  //   left: 590px !important;
  // }

  .Tab-box {
    width: 100% !important;
  }

  .tab-box-content {
    width: 75% !important;
  }

  .Tab-box>span {
    min-width: 400px !important;
  }

  .tab-container>svg {
    width: 50px !important;
    height: 50px !important;
    min-width: 30px;
  }

  .client-content {
    width: 100% !important;
  }

  .client-heading {
    font-size: 28px !important;
  }

  .contact-wrapper {
    flex-wrap: wrap-reverse !important;
    gap: 20px;
    border-radius: 25px !important;
    -webkit-border-radius: 25px !important;
    -moz-border-radius: 25px !important;
    -ms-border-radius: 25px !important;
    -o-border-radius: 25px !important;
  }

  .contect-with-us {
    width: 100% !important;

    &>div {
      width: 100% !important;
    }
  }

  .contect-with-you {
    width: 100% !important;
  }

  iframe {
    width: 100% !important;
  }

  .footer-content {
    flex-direction: column !important;
    gap: 0 !important;
  }

  .border-top-line {
    padding: 15px 0 0 !important;
    bottom: 15px !important;
  }

  #projects .common-space {
    padding: 20px 0 !important;
  }

  .tab-container {
    margin: 20px 0 !important;
  }

  .rtl .opp-text {
    margin-left: 0px !important;
  }
}

@media (max-width: 768px) {
  // .text-styled {
  //   top: 30px !important;
  //   font-size: 310px !important;
  //   letter-spacing: 0px !important;
  // }

  .about-content {
    width: 100% !important;
  }

  .about-content>.w-50>span>img {
    width: 140px !important;
  }

  .a-values {
    flex-direction: column;
    align-items: center;
    justify-content: center !important;

    &>div {
      text-align: center;
    }
  }

  .o-value {
    &>div {
      width: 50%;
    }
  }

  .achieve-bg .col {
    justify-content: center !important;
  }

  .achived-content-wrapper {
    width: auto !important;
    align-items: center !important;

    & div {
      justify-content: center !important;
    }
  }

  .achieve-box {
    width: 330px !important;
  }

  .client-content {
    align-items: center !important;
  }

  .client-content p {
    text-align: center !important;
  }

  .client-bg {
    padding: 50px 0px !important;
  }

  .slick-prev,
  .slick-next {
    top: auto !important;
    bottom: -15% !important;
    right: 0;
    left: 0;
    margin: auto;
  }

  .slick-prev {
    left: -100px !important;
    z-index: 2;
  }

  .slick-next {
    right: -100px !important;
    z-index: 2;
  }

  .client-slider {
    padding: 15px 0 !important;
  }

  .Tab-box {
    flex-direction: column !important;
  }

  .Tab-box .custom-img {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 0 !important;
  }
}

@media (max-width: 576px) {

  .nav-bar button,
  .about-social-link {
    display: none !important;
  }

  .layers {
    height: auto !important;
    min-height: 500px !important;
    width: 100%;
  }

  .text-styled {
    top: 15px !important;
    right: 6px !important;
    font-size: 170px !important;
  }

  .heading-main {
    font-size: 40px !important;
  }

  .about-house {
    display: none !important;
  }

  .about-content {
    padding: 30px 20px !important;
    width: 100% !important;
  }

  .about-bg .common-space {
    padding: 0 !important;
  }

  .about-bg .common-space-over {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .about-content .w-50 {
    width: 100% !important;
  }

  .achived-content-wrapper div {
    justify-content: center !important;
  }

  .achived-content-wrapper {
    width: auto !important;
    align-items: center !important;

    & span {
      text-align: center;
    }
  }

  .tab-box-content {
    width: 100% !important;

    & span {
      font-size: 18px !important;
    }
  }

  p {
    font-size: 14px !important;
  }

  .Tab-box>span {
    min-width: 260px !important;
  }

  .heading-mini {
    font-size: 17px !important;
  }

  .sale-service-content .lightTextGradient {
    font-size: 40px !important;
  }

  .achieve-box {
    width: 100% !important;
  }

  .client-heading {
    font-size: 20px !important;
  }

  .client-opp-boy {
    bottom: 50px !important;
    width: 180px !important;
  }

  .contact-wrapper div span {
    // font-size: 20px !important;
    text-align: center;
  }

  .about-theme-design {
    top: 0px !important;

    &>span {
      width: 35px !important;
    }
  }

  .project-theme-design {
    &>span {
      width: 35px !important;
    }
  }

  .contact-theme {
    display: none;
  }

  .contact-right {
    top: 0px !important;
  }

  .service-content-wrapper {
    padding: 10px 15px 60px !important;
  }

  .achived-content-wrapper {
    padding: 0 !important;
  }

  .Tab-box {
    flex-direction: column !important;
  }

  .Tab-box .custom-img {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 0 !important;
  }

  .house-bird {
    // bottom: -140px !important;
    width: 200px !important;
  }

  button {
    font-size: 12px !important;
  }

  .sale-service-content {
    align-items: center !important;

    &>p {
      text-align: center !important;
    }
  }

  .sale-content-scroll {
    text-align: center !important;
  }

  // .client-opp-boy {
  //   display: none !important;
  // }

  .opp-text {
    margin: 70px 0;
  }

  .contact-wrapper {
    padding: 40px 10px 0 !important;
    bottom: 50px !important;
  }

  .service-bg {
    background-size: cover !important;
    background-position: bottom !important;
  }

  .about-bg .w-25 {
    width: 100% !important;
  }

  .fIgrSW .down-btn {
    width: 60px !important;
  }

  #projects .common-space {
    padding: 10px 0 !important;
  }

  .o-value {
    justify-content: center !important;
  }

  .o-value>div {
    width: 100%;
    text-align: center !important;
    justify-content: center !important;
  }

  .footer-list {
    display: none !important;
  }

  .client-content {
    padding: 0 !important;
  }

  .footer-revised {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .house-bird {
    display: none;
  }

  .slick-prev,
  .slick-next {
    width: 40px !important;
    height: 40px !important;
  }

  .achieve-bird {
    left: 0px !important;
    width: 80px !important;
    height: 80px !important;
  }

  .loading-btn {
    top: 50% !important;
    height: 9% !important;
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  // .text-styled {
  //   top: 20px !important;
  //   right: 8px !important;
  //   font-size: 190px !important;
  // }

  // .o-value {
  //   justify-content: center !important;
  // }
  .caption {
    letter-spacing: 0 !important;
    font-size: 16px !important;
  }

  .o-value div {
    width: 44% !important;
    text-align: center;
  }

  .client-opp-boy {
    bottom: 0px !important;
    width: 180px !important;
  }

  .opp-text {
    margin: 70px 0;
    width: 50%;
  }

  .contact-wrapper {
    bottom: 30px !important;
  }

  .footer-list {
    gap: 5px 50px !important;
    flex-wrap: wrap !important;
  }

  .footer-revised {
    text-align: center;
  }

  #projects .common-space {
    padding: 0px 0 !important;
  }

  .tab-container~div {
    margin: 30px 0 !important;
  }

  .slick-prev,
  .slick-next {
    bottom: auto !important;
    top: 45% !important;
    right: auto !important;
    left: auto !important;
  }

  .slick-prev {
    left: 10px !important;
  }

  .slick-next {
    right: 10px !important;
  }

  // .achived-content-wrapper {
  //   left: 500px !important;
  // }

  .achieve-bird {
    left: 0px !important;
    width: 80px !important;
    height: 80px !important;
  }

  .darkTextGradient {
    text-align: center !important;
  }

  .whatsapp,
  .change-Btn {
    width: 60px !important;
  }
}

@media (max-width: 420px) {
  .text-styled {
    top: 20px !important;
    right: 6px !important;
    font-size: 110px !important;
  }

  // .layers {
  //   height: auto !important;
  //   min-height: 460px !important;
  //   width: 100%;
  // }
  .client-opp-boy {
    bottom: 0px !important;
  }

  .o-value {
    justify-content: center !important;
  }

  .client-heading {
    font-size: 18px !important;
  }

  .opp-text p {
    // text-align: center;
    font-size: 12px !important;
  }

  .client-opp-boy {
    bottom: 0px !important;
    width: 160px !important;
  }
}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {}

@media (max-width: 1199px) {

  .rtl .footer-logo svg,
  .rtl .footer-content svg {
    width: 150px !important;
  }
}

@media (max-width: 991px) {
  .rtl .text-styled {
    font-size: 150px !important;
    top: 0px !important;
  }

  .rtl .caption {
    line-height: 25px !important;
    bottom: 25px !important;
  }

  .rtl .about-content .w-50 {
    width: 80% !important;
  }

  .rtl .about-house {
    right: auto !important;
    left: -180px;
  }

  .rtl .o-value {
    width: 100%;
    justify-content: space-around !important;
  }

  .rtl .layer-mid-x-2 {
    position: relative;
    left: auto !important;
    right: -200px !important;
  }

  // .rtl .achived-content-wrapper {
  //   right: 570px !important;
  //   left: auto !important;
  // }
}

@media (max-width: 768px) {

  .rtl .slick-prev,
  .rtl .slick-next {
    top: auto !important;
    bottom: -20px !important;
  }

  .rtl .slick-prev {
    left: 0 !important;
    right: -100px !important;
  }

  .rtl .slick-next {
    right: 0 !important;
    left: -100px !important;
  }

  .rtl .Tab-box .custom-img {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (max-width: 576px) {
  .rtl .text-styled {
    font-size: 100px !important;
    top: -10px !important;
  }

  .rtl .caption {
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .rtl .Tab-box .custom-img {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rtl .footer-revised {
    text-align: center;
  }

  .rtl .sale-service-content .lightTextGradient,
  .rtl .heading-main {
    font-size: 25px !important;
  }

  .rtl .tab-container>svg {
    width: 25% !important;
  }
}

@media (max-width: 480px) {
  .rtl .about-content .w-50 {
    width: 100% !important;
  }

  .rtl .text-styled {
    font-size: 80px !important;
    top: 0px !important;
  }

  .rtl .slick-prev,
  .rtl .slick-next {
    bottom: auto !important;
    top: 40% !important;
    right: auto !important;
    left: auto !important;
  }

  .rtl .slick-prev {
    // left: auto !important;
    right: 10px !important;
  }

  .rtl .slick-next {
    // right: auto !important;
    left: 10px !important;
  }
}