@font-face {
  font-family: 'poppinsbold';
  src: url('../fonts/poppins-bold-webfont.woff2') format('woff2'),
    url('../fonts/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsextrabold';
  src: url('../fonts/poppins-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/poppins-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsextralight';
  src: url('../fonts/poppins-extralight-webfont.woff2') format('woff2'),
    url('../fonts/poppins-extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinslight';
  src: url('../fonts/poppins-light-webfont.woff2') format('woff2'),
    url('../fonts/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsmedium';
  src: url('../fonts/poppins-medium-webfont.woff2') format('woff2'),
    url('../fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsregular';
  src: url('../fonts/poppins-regular-webfont.woff2') format('woff2'),
    url('../fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinssemibold';
  src: url('../fonts/poppins-semibold-webfont.woff2') format('woff2'),
    url('../fonts/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsthin';
  src: url('../fonts/poppins-thin-webfont.woff2') format('woff2'),
    url('../fonts/poppins-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsblack';
  src: url('../fonts/poppins-black-webfont.woff2') format('woff2'),
    url('../fonts/poppins-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Highman Trial';
  src: url('../fonts/HighmanTrial-Regular.woff2') format('woff2'),
    url('../fonts/HighmanTrial-Regular.woff') format('woff'),
    url('../fonts/HighmanTrial-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/****************Arabic Fonts***************/

@font-face {
  font-family: "Noto Kufi Arabic Thin";
  src: url("../fonts/arabic/NotoKufiArabic-Thin.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic ExtraLight";
  src: url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Light";
  src: url("../fonts/arabic/NotoKufiArabic-Light.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Regular";
  src: url("../fonts/arabic/NotoKufiArabic-Regular.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Medium";
  src: url("../fonts/arabic/NotoKufiArabic-Medium.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic SemiBold";
  src: url("../fonts/arabic/NotoKufiArabic-SemiBold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Bold";
  src: url("../fonts/arabic/NotoKufiArabic-Bold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic ExtraBold";
  src: url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Kufi Arabic Black";
  src: url("../fonts/arabic/NotoKufiArabic-Black.woff2") format("woff2"),
    url("../fonts/arabic/NotoKufiArabic-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {



  --headingFont: "Highman Trial";
  --bold: "poppinsbold";
  --extrabold: "poppinsextrabold";
  --extralight: "poppinsextralight";
  --light: "poppinslight";
  --medium: "poppinsmedium";
  --regular: "poppinsregular";
  --semibold: "poppinssemibold";
  --thin: "poppinsthin";
  --textblack: "poppinsblack";


  --arThin: "Noto Kufi Arabic Thin";
  --arExtraLight: "Noto Kufi Arabic ExtraLight";
  --arLight: "Noto Kufi Arabic Light";
  --arRegular: "Noto Kufi Arabic Regular";
  --arMedium: "Noto Kufi Arabic Medium";
  --arSemiBold: "Noto Kufi Arabic SemiBold";
  --arBold: "Noto Kufi Arabic Bold";
  --arExtraBold: "Noto Kufi Arabic ExtraBold";
  --arBlack: "Noto Kufi Arabic Black";
}