@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}

.main-artical {
    overflow-x: hidden;
}

// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     overflow-x: hidden;
// }

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background-color: var(--bgColor2);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    cursor: pointer;
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
}

p {
    font-size: 15px;
    font-family: var(--regular);
    // padding-bottom: 15px;
}

*::-moz-selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--themeColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

.themeColor {
    color: var(--themeColor);
}

.darkBlue {
    color: var(--darkBlue);
}

.textPalceholder {
    color: var(--textPalceholder);
}

.whiteColor {
    color: var(--whiteColor);
}

.darkTextGradient {
    background: var(--DarkText);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

.lightTextGradient {
    background: var(--lightText);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.ButtonColorGradient {
    background: var(--ButtonColor);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.bg-textGrey {
    background: var(--textGrey);
}

.bg-white {
    background: var(--whiteColor);
}

.bg-themeColor {
    background: var(--themeColor);
}

.bg-darkBlue {
    background: var(--darkBlue);
}

.bg-Color {
    background: var(--bgColor);
}

.bg-Color2 {
    background: var(--bgColor2);
}

/* header-btn */
.header-btn {
    overflow: hidden;
}

.header-btn::after {
    height: 100%;
    left: -35% !important;
    top: 0 !important;
    transform: skew(50deg);
    transition-duration: 0.6s !important;
    transform-origin: top left;
    width: 0;
}

.header-btn:hover:after {
    height: 100%;
    width: 135%;
}

.common-space {
    padding: 50px 0;
}

.common-space-over {
    margin-top: 100px;
    margin-bottom: 100px;
}

.d-Flex {
    display: flex;
    align-items: center;
}

.d-Flex-column {
    padding: 120px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.banner-bg {
    position: relative;
    background-image: url('../images/png/banner-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.banner-bg::after {
    content: '';
    width: 100%;
    // height: 100vh;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    background-image: url('../images/png/parallex-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;

}

.about-bg {
    position: relative;
    background-image: url('../images/png/about-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}


.about-content {
    background-image: url('../images/png/about-bg-2.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}

.service-bg {
    background-image: url('../images/png/service-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom;
}

.achieve-bg {
    position: relative;
    background-image: url('../images/png/achieve-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    .lazy-load-image-background {
        position: relative !important;
        width: 100% !important;
    }
}

.client-bg {
    background-image: url('../images/png/client-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding: 80px 0;
}

.client-bg-2 {
    background-image: url('../images/png/client-bg-2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.contact-main-bg {
    position: relative;
    background-image: url('../images/png/contact-main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.contact-wrap-bg {
    position: relative;
    background-image: url('../images/png/contact-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
}

.border-bottom-line {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 1%;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: #78839A;
    }
}

.border-top-line {
    position: relative;
    padding: 25px 0 0;
    bottom: 25px;

    &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 1%;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        background-color: #E3E3E3;
    }
}

.social-link {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: auto;
    display: flex;
    gap: 15px;
    z-index: 1000;
    height: 200px;
    flex-wrap: wrap;
    width: 60px;

    & svg {
        cursor: pointer;
    }
}

/* Scrollbar Styling */
.sale-content-scroll::-webkit-scrollbar {
    width: 6px;
}

.sale-content-scroll::-webkit-scrollbar-track {
    position: relative;
    background-color: #ffe6a864;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
}

.sale-content-scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--DarkText);

    &::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 10px;
        background-color: var(--DarkText);
    }
}

iframe {
    width: 490px;
    height: 310px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

/***************** Parallex effectg css*************************/
:root {
    --index: calc(1vw + 1vh);
    --transition: transform 0.75s cub-bezier(0.075, 0.5, 0, 1);
}

.content {
    will-change: transform;
}

.header-main {
    position: relative;
}

.header-main::after {
    content: '';
    position: absolute;
    z-index: 10;
    width: 100%;
    height: calc(var(--index) * 10);
    background: url('../images/png/ground.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    bottom: calc(var(--index) * -4.5);
}

.layers {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    height: 100vh;
}

.layer-head {
    z-index: 1;
    transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 2), 0);
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
    will-change: transform;
}

.caption {
    transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
}

.img-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover !important;
    background-position: center !important;
    will-change: transform;
    z-index: 2;
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}

.layer-base {
    transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 1.6), 0);
    z-index: 0;
}

.layer-mid {
    transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 6), 0);
}

.layer-mid-up {
    transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ -6), 0);
}

.layer-front {
    transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -webkit-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -moz-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -ms-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
    -o-transform: translate3d(0, calc(var(--scrollTop)/ 4.5), 0);
}

.artical-main {
    --mainarticle-transform: translate3d(0, calc(var(--scrollTop)/ -7.6));
    position: relative;
    top: -2px;
    z-index: 10;
}

.layer-mid-2 {
    transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -webkit-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -moz-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -ms-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
    -o-transform: translate3d(calc(var(--scrollTop)/ 5.6), 0, 0);
}

// .layer-mid-X {
//     transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -webkit-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -moz-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -ms-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
//     -o-transform: translate3d(calc(var(--scrollTop)/ -5), 0, 0);
// }

// .layer-mid-x-2 {
//     position: relative;
//     left: -370px;
//     transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -webkit-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -moz-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -ms-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
//     -o-transform: translate3d(calc(var(--scrollTop)/ 2), 0, 0);
// }

.layer-mid-3 {
    transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -webkit-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -moz-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -ms-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
    -o-transform: translate3d(calc(var(--scrollTop)/ -5.6), 0, 0);
}

.arrows:hover {
    fill: var(--darkBlue);
    stroke: var(--whiteColor);
}

.arrows:hover>circle {
    fill: var(--darkBlue);
}

.message {
    position: fixed !important;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto !important;
}












/***************** RTL DIrection*************************/

html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body .rtl {
    position: relative !important;
    font-family: var(--arRegular);
    font-style: normal;
    font-size: 15px;
}

.rtl .about-house,
.rtl .house-bird,
.rtl .click-btn>svg,
.rtl .about-theme-design,
.rtl .service-boy,
.rtl .achieve-bg,
.rtl .project-theme-design,
.rtl .achieve-bg .col,
.rtl .client-bg,
.rtl .client-bg .col,
.rtl .tab-container svg,
.rtl .slick-slider svg,
.rtl .contact-svg,
.rtl .contact-main-bg,
.rtl .contact-main-bg .col,
.rtl footer,
.rtl .about-bg,
.rtl .about-bg .col,
.rtl .achieve-bird {
    transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -ms-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
}

.rtl .project-theme-design {
    transform: scaleX(1) !important;
    -webkit-transform: scaleX(1) !important;
    -moz-transform: scaleX(1) !important;
    -ms-transform: scaleX(1) !important;
    -o-transform: scaleX(1) !important;
}

.rtl .nav-bar-list a,
.rtl .footer-list a {
    font-family: var(--arSemiBold) !important;
}

.rtl .nav-bar-list a.active {
    font-family: var(--arExtraBold) !important;
}

.rtl .service-list {
    gap: 28px !important;
}

.rtl .social-link {
    right: auto;
    left: 30px;
}

.rtl .header-btn span {
    direction: ltr;
}

.rtl .about-house {
    right: auto !important;
    left: -320px;
}

.rtl .house-bird {
    right: auto !important;
    left: 420px !important;
}

.rtl .achieve-bird {
    left: auto !important;
    right: -150px !important;
}

.rtl .about-social-link {
    right: -18px;
    left: auto !important;
}

.rtl .about-theme-design {
    right: auto;
    left: 0;
}

.rtl .Tab-box .custom-img {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 10PX;
    border-bottom-right-radius: 10PX;
}

.rtl .slick-prev {
    left: auto !important;
    right: -65px;
}

.rtl .slick-next {
    right: auto !important;
    left: -75px !important;
}

.rtl .slick-prev,
.rtl .slick-next {
    top: 35% !important;
}

.rtl .caption,
.rtl .text-styled {
    letter-spacing: 0 !important;
}

.rtl .caption {
    line-height: 40px !important;
    position: relative;
    bottom: 50px;
}

.rtl .text-styled {
    font-size: 250px !important;
    top: -40px !important;
}

.rtl .about-content .w-50 {
    width: 55% !important;
}

.rtl .o-value {
    width: 70%;
    justify-content: space-around !important;
}

.rtl .sale-service-content .lightTextGradient,
.rtl .heading-main {
    font-size: 30px !important;
}

// .rtl .service-boy {
//     left: 820px !important;
//     bottom: 0px !important;
//     right: auto !important;
// }

.rtl .tab-box-content {
    padding: 60px 25px !important;
    gap: 30px !important;
}

.rtl .contect-with-us>span,
.rtl .contect-with-you>span {
    font-size: 20px;
}

.rtl .field-box input::placeholder,
.rtl textarea::placeholder {
    font-family: var(--arRegular);
}

.rtl .heading-mini {
    letter-spacing: 0 !important;
}

.rtl .contact-text {
    direction: ltr;
}

.rtl .loading-btn {
    font-family: var(--arRegular) !important;
}

.rtl .contact-social-links {
    position: relative;
    right: 15px;
}

.rtl .Toastify__toast-body>div {
    font-family: var(--arRegular) !important;
}

.rtl .opp-text {
    margin-left: 140px;
}

// .rtl .achived-content-wrapper {
//     right: 450px !important;
//     left: auto !important;
//     transform: translate3d(calc(var(--scrollTop)/ 5), 0, 0);
//     -webkit-transform: translate3d(calc(var(--scrollTop)/ 5), 0, 0);
//     -moz-transform: translate3d(calc(var(--scrollTop)/ 5), 0, 0);
//     -ms-transform: translate3d(calc(var(--scrollTop)/ 5), 0, 0);
//     -o-transform: translate3d(calc(var(--scrollTop)/ 5), 0, 0);
// }

// .rtl .layer-mid-x-2 {
//     position: relative;
//     left: auto !important;
//     right: -370px !important;
//     transform: translate3d(calc(var(--scrollTop)/ -2), 0, 0);
//     -webkit-transform: translate3d(calc(var(--scrollTop)/ -2), 0, 0);
//     -moz-transform: translate3d(calc(var(--scrollTop)/ -2), 0, 0);
//     -ms-transform: translate3d(calc(var(--scrollTop)/ -2), 0, 0);
//     -o-transform: translate3d(calc(var(--scrollTop)/ -2), 0, 0);
// }